<template>
  <v-container fluid>
   <v-card :max-width="IS_MOBILE ? '100%' : '40vw'">
     <AddEditComponent
       class="create-host-component"
       :pCardTitle="'New Host'"
       :pEditedItem="editedHost"
       :pEditedItemType="HOST_ADD_EDIT_ITEM"
       :pItemParams="HOST_PARAMS"
       :pLoading="loading"
       :pPermanentActive="true"
       :pShowCancelBtn="false"
       :pDisabledParams="[]"
       @onItemCreatedOrUpdated="saveHostAndCloseDialog($event)"
     />
   </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import {
  dismissAction, logAndExtractMessage, HOST_ADD_EDIT_ITEM, HOST_PARAMS,
} from "@/utils/utils";
import { IS_MOBILE } from "@/utils/is-mobile";

export default {
  name: "CreateHost",

  components: {
    AddEditComponent: () => import("./custom-components/AddEditComponent.vue"),
  },

  data: () => ({
    IS_MOBILE,
    HOST_ADD_EDIT_ITEM,
    HOST_PARAMS,

    editedHost: {},
    loading: false,
  }),

  methods: {
    async saveHostAndCloseDialog(host) {
      this.loading = true;

      try {
        const createdHost = await this.$store.dispatch("hostModule/addOrSaveHost", host);
        await this.$store.dispatch("userModule/loadUserData");
        await this.$store.commit("userModule/setActiveHostId", createdHost.id);
        await this.$router.push({ path: "/active-host" });
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.row-combobox {
  margin-top: 0 !important;
}
</style>
